<template>
    <div class="invoice-apply">
        <el-dialog
                title="发票信息"
                :visible.sync="dialogVisible"
                width="600px"
                @close="handleClose">
            <div>
                <div class="mb-3">
                    <el-radio-group v-model="invoiceType" @change="invoiceTypeChange">
                        <el-radio-button label="1">电子普通发票</el-radio-button>
                        <el-radio-button label="2">增值税专用发票</el-radio-button>
                    </el-radio-group>
                </div>
                <div v-show="invoiceType === '1'">
                    <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="120px" class="demo-ruleForm">
                        <el-form-item label="发票抬头">
                            <el-radio-group v-model="invoiceHeaderType" @change="invoiceHeaderTypeChange">
                                <el-radio-button label="OWN">个人</el-radio-button>
                                <el-radio-button label="COMPANY">单位</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item :label="invoiceHeaderType === 'OWN'? '个人名称' : '单位名称'"  prop="headerName">
                            <!--                            <el-input v-model="dataForm.headerName" :placeholder="dataForm.rise === 'OWN'? '请输入个人名称' : '请输入单位名称'"></el-input>-->
                            <el-autocomplete
                                    prefix-icon="el-icon-search"
                                    style="width:100%;"
                                    clearable
                                    v-model="dataForm.headerName"
                                    :fetch-suggestions="headerNameSelect"
                                    placeholder="请输入内容"
                                    @select="handleSelect"
                                    @change="headerNameChange">
                                <template slot-scope="{ item }">
                                    <div class="name">
                                        <span>{{ item.headerName }}</span>
                                        <span class="ml-3" v-if="item.dutyParagraph">{{ item.dutyParagraph }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="纳税人识别号" prop="dutyParagraph" v-if="invoiceHeaderType === 'COMPANY'">
                            <el-input v-model="dataForm.dutyParagraph" placeholder="请输入纳税人识别号"></el-input>
                        </el-form-item>
                        <el-form-item label="更多选填项" v-if="invoiceHeaderType === 'COMPANY'">
                            <div class="uf uf-ac">
                                <div class="op-05">填写单位地址、电话、开户银行及账号</div>
                                <div @click="isMore = !isMore" class="ml-2 uf uf-ac cp">
                                    <i :class="isMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                    <div class="ml-1" style="color: #409EFF">{{isMore ? '收起' : '展开'}}</div>
                                </div>
                            </div>
                            <div v-if="isMore">
                                <el-form-item label="单位地址" v-if="invoiceHeaderType === 'COMPANY'" label-width="80px">
                                    <el-input v-model="dataForm.registerAddress" placeholder="请输入单位地址" class="mb-3"
                                              type="textarea"></el-input>
                                </el-form-item>
                                <el-form-item label="单位电话" v-if="invoiceHeaderType === 'COMPANY'" label-width="80px">
                                    <el-input v-model="dataForm.registerPhone" placeholder="请输入单位电话" class="mb-3"></el-input>
                                </el-form-item>
                                <el-form-item label="开户银行" v-if="invoiceHeaderType === 'COMPANY'" label-width="80px">
                                    <el-input v-model="dataForm.bankName" placeholder="请输入开户银行" class="mb-3"></el-input>
                                </el-form-item>
                                <el-form-item label="银行账号" v-if="invoiceHeaderType === 'COMPANY'" label-width="80px">
                                    <el-input v-model="dataForm.bankNo" placeholder="请输入银行账号"></el-input>
                                </el-form-item>
                            </div>
                        </el-form-item>
                        <el-form-item label="授权文件">
                            <div>
                                <el-upload
                                        class="upload-demo"
                                        :action="$cons.API_SERVER_PATH + $api.INVOICE.UPLOAD_AUTHORIZE"
                                        :headers="uploadHeaders"
                                        :limit="1"
                                        :before-remove="beforeRemove"
                                        :on-remove="handleRemove"
                                        :on-success="handleSuccess"
                                        :on-preview="handlePreview"
                                        :on-exceed="handleExceed"
                                        :file-list="fileList">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                </el-upload>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="uf uf-ac uf-pe">
                      <span slot="footer" class="dialog-footer">
                         <el-button @click="dialogVisible = false">取 消</el-button>
                         <el-button type="primary" @click="ordinarySave">确 定</el-button>
                       </span>
                    </div>
                </div>
                <div v-show="invoiceType === '2'">
                    <el-form :model="ruleForm" :rules="ruleFormRules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                        <el-form-item  prop="headerName" label="单位名称">
<!--                            <el-input v-model="ruleForm.headerName" placeholder="请输入单位名称"></el-input>-->
                            <el-autocomplete
                                    prefix-icon="el-icon-search"
                                    style="width:100%;"
                                    clearable
                                    v-model="ruleForm.headerName"
                                    :fetch-suggestions="headerNameNameFetch"
                                    placeholder="请输入内容"
                                    @select="incrementSelect">
                                <template slot-scope="{ item }">
                                    <div class="name">
                                        <span>{{ item.headerName }}</span>
                                        <span class="ml-3">{{ item.dutyParagraph }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="纳税人识别码" prop="dutyParagraph">
                            <el-input v-model="ruleForm.dutyParagraph" placeholder="请输入纳税人识别码"></el-input>
                        </el-form-item>
                        <el-form-item label="注册地址" prop="registerAddress">
                            <el-input v-model="ruleForm.registerAddress" placeholder="请输入注册地址"></el-input>
                        </el-form-item>
                        <el-form-item label="注册电话" prop="registerPhone">
                            <el-input v-model="ruleForm.registerPhone" placeholder="请输入注册电话"></el-input>
                        </el-form-item>
                        <el-form-item label="开户银行" prop="bankName">
                            <el-input v-model="ruleForm.bankName" placeholder="请输入开户银行"></el-input>
                        </el-form-item>
                        <el-form-item label="银行账号" prop="bankNo">
                            <el-input v-model="ruleForm.bankNo" placeholder="请输入银行账号"></el-input>
                        </el-form-item>
                        <el-form-item  prop="receiveName" label="收票人姓名">
<!--                            <el-input v-model="ruleForm.receiveName" placeholder="请输入收票人姓名"></el-input>-->
                            <el-autocomplete
                                    prefix-icon="el-icon-search"
                                    style="width:100%;"
                                    clearable
                                    v-model="ruleForm.receiveName"
                                    :fetch-suggestions="receiveNameFetch"
                                    placeholder="请输入内容"
                                    @select="receiveNameSelect">
                                <template slot-scope="{ item }">
                                    <div class="name">
                                        <span>{{ item.receiveName }}</span>
                                        <span class="ml-3">{{ item.mobile }}</span>
                                    </div>
                                    <span class="f12 op-05 addr">{{ item.provinceCode + item.cityCode + item.countyCode + item.address }}</span>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="收票人手机" prop="receiveMobile">
                            <el-input v-model="ruleForm.receiveMobile" placeholder="请输入收票人手机"></el-input>
                        </el-form-item>
                        <el-form-item label="所在地区" v-if="areaUpdate">
                            <div class="uf uf-ac uf-pj" >
                                <el-input v-model="area"  clearable  disabled></el-input>
                                <div class="ml-2">
                                    <el-button type="text" size="small" @click="areaUpdate = !areaUpdate">修改</el-button>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="所在地区" prop="area" v-else>
                            <el-cascader
                                    class="wi100"
                                    :options="options"
                                    v-model="ruleForm.area"
                                    @change="handleChange">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="receiveAddress">
                            <el-input v-model="ruleForm.receiveAddress" type="textarea" placeholder="请输入详细地址"></el-input>
                        </el-form-item>
                        <el-form-item label="授权文件">
                            <div>
                                <el-upload
                                        class="upload-demo"
                                        :action="$cons.API_SERVER_PATH + $api.INVOICE.UPLOAD_AUTHORIZE"
                                        :headers="uploadHeaders"
                                        :limit="1"
                                        :before-remove="beforeRemove"
                                        :on-remove="handleRemove"
                                        :on-success="handleSuccess"
                                        :on-preview="handlePreview"
                                        :on-exceed="handleExceed"
                                        :file-list="fileList">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                </el-upload>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <div class="uf uf-ac uf-pe">
                                <el-button @click="handleClose">取消</el-button>
                                <el-button type="primary" @click="incrementSave">保存</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!--            生传文件查看-->
            <el-dialog
                    width="40%"
                    title="发票授权文件"
                    :visible.sync="innerVisible"
                    append-to-body>
                <div class="uf uf-ac uf-pc">
                    <el-image :src="imageUrl" :preview-src-list="[imageUrl]"></el-image>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {regionData, CodeToText} from 'element-china-area-data'
    export default {
        name: 'invoice-apply',
        data () {
            return {
                dialogVisible: false,
                innerVisible: false,
                invoiceType: '1',
                invoiceHeaderType: 'OWN',
                isMore: false,
                // 电子普通发票
                dataForm: {
                    invoiceType: '',
                    invoiceHeaderType: '',
                    headerName: '',
                    dutyParagraph: '',
                    registerAddress: '',
                    registerPhone: '',
                    bankName: '',
                    bankNo: ''
                },
                rules: {
                    headerName: [
                        { required: true, message: '请输入', trigger: ['blur', 'change'] }
                    ],
                    dutyParagraph: [
                        { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
                    ]
                },
                // 增值税专用发票
                ruleForm: {
                    headerName: '',
                    dutyParagraph: '',
                    registerAddress: '',
                    registerPhone: '',
                    bankName: '',
                    bankNo: '',
                    receiveName: '',
                    receiveMobile: '',
                    receiveProvinceCode: '',
                    receiveCityCode: '',
                    receiveCountyCode: '',
                    receiveAddress: ''
                },
                ruleFormRules: {
                    headerName: [
                        { required: true, message: '请输入单位名称', trigger: 'blur' }
                    ],
                    dutyParagraph: [
                        { required: true, message: '请输入纳税人识别码', trigger: 'blur' }
                    ],
                    registerAddress: [
                        { required: true, message: '请输入注册地址', trigger: 'blur' }
                    ],
                    registerPhone: [
                        { required: true, message: '请输入注册电话', trigger: 'blur' }
                    ],
                    bankName: [
                        { required: true, message: '请输入开户银行', trigger: 'blur' }
                    ],
                    bankNo: [
                        { required: true, message: '请输入银行账号', trigger: 'blur' }
                    ],
                    receiveName: [
                        { required: true, message: '请输入收票人', trigger: 'blur' }
                    ],
                    receiveMobile: [
                        { required: true, message: '请输入收票人电话', trigger: 'blur' }
                    ],
                    area: [
                        { required: true, message: '请输入收票人所在地', trigger: 'blur' }
                    ],
                    receiveAddress: [
                        { required: true, message: '请输入详细地址', trigger: 'blur' }
                    ]
                },
                areaUpdate: false,
                area: '',
                options: regionData,
                selectedOptions: [],
                uploadData: {},
                uploadHeaders: {
                    token: Vue.cookie.get('token')
                },
                fileList: [],
                // 订单id
                id: '',
                orderId: '',
                isReapply: false,
                imageUrl: '',
                authorizationPath: '',
                submitNum: ''
            }
        },
        created () {
            this.$getDictList('INVOICE_TYPE', dict => {
            })
        },
        methods: {
            //    初始化
            init (item, isReapply = false) {
                this.dialogVisible = true
                if (item && item.id) {
                    this.id = this.$deepClone(item.id)
                    this.submitNum = this.$deepClone(item.submitNum || 1)
                    if (isReapply) {
                        this.invoiceType = item.invoiceType || ''
                        this.invoiceHeaderType = item.invoiceHeaderType || ''
                        this.orderId = this.$deepClone(item.orderId)
                        this.isReapply = true
                        if (item.invoiceType === '1') {
                            this.dataForm = this.$deepClone(item)
                            this.$delete(this.dataForm, 'serviceOrderEntity')
                        } else {
                            this.ruleForm = this.$deepClone(item)
                            this.$delete(this.ruleForm, 'serviceOrderEntity')
                            this.areaUpdate = true
                            this.area = this.ruleForm.receiveProvinceCode + '/' + this.ruleForm.receiveCityCode + '/' + this.ruleForm.receiveCountyCode
                        }
                        if (item.authorizationPath) {
                            this.fileList = [{url: item.authorizationPath, name: '授权文件'}]
                            this.imageUrl = this.$cons.SERVER_PATH + item.authorizationPath
                        }
                    }
                }
            },
            // 发票类型切换
            invoiceTypeChange (val) {
                if (val === '2') {
                    this.getDataInfo()
                    this.getAddressList()
                }
            },
            // 抬头类型切换
            invoiceHeaderTypeChange () {
                this.dataForm.headerName = ''
                this.$nextTick(() => {
                    this.$refs.dataForm.clearValidate()
                })
            },
            //    获取增票资质信息
            getDataInfo () {
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.RISE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        type: 'COMPANY'
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let list = data.list || []
                        if (list && list.length > 0) {
                            let dataInfo = list[0]
                            this.ruleForm.headerName = dataInfo.headerName || ''
                            this.ruleForm.dutyParagraph = dataInfo.dutyParagraph || ''
                            this.ruleForm.registerAddress = dataInfo.registerAddress || ''
                            this.ruleForm.registerPhone = dataInfo.registerPhone || ''
                            this.ruleForm.bankName = dataInfo.bankName || ''
                            this.ruleForm.bankNo = dataInfo.bankNo || ''
                        }
                    }
                })
            },
            //   获取收票地址列表
            getAddressList () {
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.ADDRESS_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: 1,
                        limit: 50
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        let list = page.list || []
                        if (list && list.length > 0) {
                            let defaultList = list.filter(item => {
                                return item.isDefault === 1
                            })
                            let defaultAddress = defaultList && defaultList.length > 0 ? this.$deepClone(defaultList[0]) : this.$deepClone(list[0])
                            this.areaUpdate = true
                            this.area = defaultAddress.provinceCode + '/' + defaultAddress.cityCode + '/' + defaultAddress.countyCode
                            this.ruleForm.receiveName = defaultAddress.receiveName || ''
                            this.ruleForm.receiveMobile = defaultAddress.mobile || ''
                            this.ruleForm.receiveAddress = defaultAddress.address || ''
                            this.ruleForm.receiveProvinceCode = defaultAddress.provinceCode || ''
                            this.ruleForm.receiveCityCode = defaultAddress.cityCode || ''
                            this.ruleForm.receiveCountyCode = defaultAddress.countyCode || ''
                        }
                    }
                })
            },
            //  输入框远程搜索(抬头)
            headerNameSelect (value, cb) {
                let list = []
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.RISE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        type: this.invoiceHeaderType
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        list = data.list || []
                    }
                    cb(list)
                })
            },
            // 抬头检索选择
            handleSelect (item) {
                this.dataForm.headerName = item.headerName || ''
                this.dataForm.dutyParagraph = item.dutyParagraph || ''
                this.dataForm.registerAddress = item.registerAddress || ''
                this.dataForm.registerPhone = item.registerPhone || ''
                this.dataForm.bankName = item.bankName || ''
                this.dataForm.bankNo = item.bankNo || ''
                this.invoiceHeaderType = this.$deepClone(item.type)
                if (this.authorizationPath) {
                    this.dataForm.authorizationPath = this.authorizationPath
                }
            },
            headerNameChange (item) {
                // this.dataForm.pubMedicineEntity.creatorType = 'CLINIC_CREATED'
                // this.dataForm.pubMedicineEntity.id = ''
            },
            // 增值税--单位抬头查询
            headerNameNameFetch (value, cb) {
                let list = []
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.RISE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        type: 'COMPANY'
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        list = data.list || []
                    }
                    cb(list)
                })
            },
            // 增值税--单位抬头选择 increment
            incrementSelect (item) {
                let dataInfo = this.$deepClone(item)
                this.ruleForm.headerName = dataInfo.headerName || ''
                this.ruleForm.dutyParagraph = dataInfo.dutyParagraph || ''
                this.ruleForm.registerAddress = dataInfo.registerAddress || ''
                this.ruleForm.registerPhone = dataInfo.registerPhone || ''
                this.ruleForm.bankName = dataInfo.bankName || ''
                this.ruleForm.bankNo = dataInfo.bankNo || ''
            },
            // 收票地址查询
            receiveNameFetch (value, cb) {
                let list = []
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.ADDRESS_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: 1,
                        limit: 10
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        list = page.list || []
                    }
                    cb(list)
                })
            },
            // 收票人选择
            receiveNameSelect (item) {
                let defaultAddress = this.$deepClone(item)
                this.areaUpdate = true
                this.area = defaultAddress.provinceCode + '/' + defaultAddress.cityCode + '/' + defaultAddress.countyCode
                this.ruleForm.receiveName = defaultAddress.receiveName || ''
                this.ruleForm.receiveMobile = defaultAddress.mobile || ''
                this.ruleForm.receiveAddress = defaultAddress.address || ''
                this.ruleForm.receiveProvinceCode = defaultAddress.provinceCode || ''
                this.ruleForm.receiveCityCode = defaultAddress.cityCode || ''
                this.ruleForm.receiveCountyCode = defaultAddress.countyCode || ''
            },
            handleChange(val) {
                if (val) {
                    var loc = []
                    for (let i = 0; i < this.ruleForm.area.length; i++) {
                        loc.push(CodeToText[this.ruleForm.area[i]])
                    }
                    this.ruleForm.receiveProvinceCode = loc[0] || ''
                    this.ruleForm.receiveCityCode = loc[1] || ''
                    this.ruleForm.receiveCountyCode = loc[2] || ''
                }
            },
            //   图片上传成功
            handleSuccess (res, file, fileList) {
                if (res && res.code === 0) {
                    this.dataForm.authorizationPath = res.path || ''
                    this.authorizationPath = res.path || ''
                    this.fileList = [file]
                    this.imageUrl = URL.createObjectURL(file.raw)
                } else {
                    this.$message.error(res.msg)
                    this.fileList = []
                }
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`)
            },
            handleRemove(file, fileList) {
                this.fileList = []
                this.dataForm.authorizationPath = ''
                this.$message.success('删除成功')
            },
            //    查看图片
            handlePreview (file) {
                this.$nextTick(() => {
                    this.innerVisible = true
                })
            },
            //   上传文件超出限制
            handleExceed (files, fileList) {
                this.$message.warning('当前已有授权文件，如需重新上传请删除原来文件！')
            },
            //    电子保存
            ordinarySave () {
                this.$refs.dataForm.validate((valid) => {
                    if (valid) {
                        let data = this.$deepClone(this.dataForm)
                        data.invoiceType = this.invoiceType || ''
                        data.invoiceHeaderType = this.invoiceHeaderType || ''
                        if (this.isReapply) {
                            data.id = this.id || ''
                            data.orderId = this.orderId || ''
                            data.submitNum = this.submitNum || ''
                        } else {
                            data.orderId = this.id || ''
                        }
                        this.onSubmit(data)
                    }
                })
            },
            //    增值保存
            incrementSave () {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let data = this.$deepClone(this.ruleForm)
                        if (this.dataForm.authorizationPath) {
                            data.authorizationPath = this.dataForm.authorizationPath
                        }
                        data.invoiceType = this.invoiceType || ''
                        if (this.isReapply) {
                            data.id = this.id || ''
                            data.orderId = this.orderId || ''
                            data.submitNum = this.submitNum || ''
                        } else {
                            data.orderId = this.id || ''
                        }
                        this.onSubmit(data)
                    }
                })
            },
            // 提交开票申请
            onSubmit (data) {
                this.$http({
                    url: this.$http.adornUrl(this.isReapply ? this.$api.INVOICE.INVOICE_AGAIN : this.$api.INVOICE.INVOICE_SAVE),
                    method: 'POST',
                    data: this.$http.adornData({
                        ...data
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message.success('保存成功')
                        this.dialogVisible = false
                        this.$emit('invoiceCallBack', true)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            //    关闭
            handleClose () {
                this.dialogVisible = false
                this.$emit('invoiceCallBack')
            }
        }
    }
</script>

<style scoped>
    .invoice-apply /deep/ .el-dialog__body {
        padding: 10px 20px;
    }

</style>
