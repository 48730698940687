<template>
  <div>
    <el-page-header @back="$emit('goBack', true)">
      <span slot="content" class="fwb">订单详情</span>
    </el-page-header>
    <el-divider></el-divider>
    <el-steps v-if="stepActive !== 8" :active="stepActive" align-center finish-status="success">
      <el-step :title="item.title" :description="item.description" v-for="item in step" :key="item.title"></el-step>
    </el-steps>
    <div class="close" v-else>订单已关闭</div>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px; margin-top:20px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>订单信息</h3>
      </div>
      <el-row :gutter="20" class="row-box">
        <el-row>
          <el-col :span="8">
            <label class='fontsize'>订单号：</label><span>{{ dataForm.orderNo }}</span>
          </el-col>
          <el-col :span="8">
            <label class='fontsize'>服务项：</label><span>{{ dataForm.itemName }}</span>
          </el-col>
          <el-col :span="8">
            <label class='fontsize'>服务产品：</label><span>{{ dataForm.serviceName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <label class='fontsize'>支付状态：</label>
            <el-tag type="danger" v-if="dataForm.payStatus === 1">{{ payStatusDict[dataForm.payStatus] }}</el-tag>
            <el-tag type="success" v-if="dataForm.payStatus != 1">{{ payStatusDict[dataForm.payStatus] }}</el-tag>
          </el-col>
          <el-col :span="8">
            <label class='fontsize'>订单状态：</label>
            <el-tag type="success" v-if="dataForm.orderStatus === 3">{{ orderStatusDict[dataForm.orderStatus] }}</el-tag>
            <el-tag type="danger" v-if="dataForm.orderStatus === 8">{{ orderStatusDict[dataForm.orderStatus] }}</el-tag>
            <el-tag v-if="dataForm.orderStatus != 3 && dataForm.orderStatus != 8">{{ orderStatusDict[dataForm.orderStatus] }}</el-tag>
          </el-col>
          <el-col :span="8" v-if="dataForm.orderStatus === 1">
            <label class='fontsize'>办理状态：</label><span>订单尚未确认</span>
          </el-col>
          <el-col :span="8" v-if="dataForm.orderStatus === 2 && dataForm.logEntityList.length > 0">
            <label class='fontsize'>办理状态：</label><span>{{ dataForm.deliveryEntity.logList[dataForm.deliveryEntity.logList.length - 1].notes}}</span>
          </el-col>
        </el-row>
        <el-row>
<!--          <el-col :span="8">-->
<!--            <label class='fontsize'>需求编号：</label><span>{{ dataForm.solutionProductId === null ? '无' :  dataForm.solutionProductId }}</span>-->
<!--          </el-col>-->
          <el-col :span="8">
            <label class='fontsize'>方案号：</label><span>{{ dataForm.solutionNo === null ? '无' : dataForm.solutionNo }}</span>
          </el-col>
          <el-col :span="8">
            <label class='fontsize'>公司名称：</label><span>{{ dataForm.company || '无'}}</span>
          </el-col>
        </el-row>
        <el-row>
        </el-row>
      </el-row>
    </el-card>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px; margin-top:20px">
    <div class="i-title">
      <h3><i class="el-icon-s-order mr-1"></i>附件信息</h3>
    </div>
      <div v-if="dataForm.orderFile">
        <a :href="$cons.SERVER_PATH + this.dataForm.orderFile"><img src="~@/assets/img/ZIP.png" style="width: 20px; height: 20px; vertical-align: middle">附件材料查看</a>
      </div>
      <div v-else>
        <div class="uf uf-ac mt-3" style="margin-bottom: 30px">
          <div class="sign"></div>
          <div style="font-weight: bold;color: #1a2633;">附件上传：</div>
          <el-upload
            class="upload-demo"
            action="#"
            :on-change="handleUpload"
            :http-request="httpRequest"
            :on-remove="handleRemove"
            :before-upload="handleBeforeUpload"
            :before-remove="beforeRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            ref="myupload">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div v-if="dataForm.templateFilePath">
          <a :href="$cons.SERVER_PATH + this.dataForm.templateFilePath" style="margin: 45px;"><img src="~@/assets/img/ZIP.png" style="width: 20px; height: 20px; vertical-align: middle">模板文件下载</a>
          <div class="uf uf-ac ml-5 c-base" style="margin-top: 5px">
            <i class="el-icon-warning "></i>
            <div class="ml-1 f14 "> 下载模板文件查看需要上传材料</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>支付信息</h3>
      </div>
        <el-row :gutter="20" class="row-box">
        <el-col :span="8">
          <label class='fontsize'>支付方式：</label><span>{{ payTypeDict[dataForm.payType] }}</span>
        </el-col>
        <template>
          <el-col :span="8" v-if="dataForm.phaseTwo > 0">
            <label class='fontsize'>付款方式：</label><span>分期支付</span>
          </el-col>
          <el-col :span="8" v-else>
            <label class='fontsize'>付款方式：</label><span>全款支付</span>
          </el-col>
        </template>
        <el-col :span="8">
          <label class='fontsize'>支付是否完成：</label><span>{{ payCompleteDict[dataForm.payComplete] }}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>单价：</label><span> {{!((dataForm.price || 0) >=0) ? '面议' : (dataForm.price || 0).toFixed(2) +'元'}}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>单价类型：</label><span>{{ isNull(dataForm.priceType) ? '每件' : priceTypeDict[dataForm.priceType] }}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>产品数量：</label><span>{{ dataForm.quantity }}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>订单金额：</label><span> {{!((dataForm.totalSum || 0) >=0) ? '面议' : (dataForm.totalSum || 0).toFixed(2) +'元'}}</span>
        </el-col>
          <template v-if="dataForm.phaseTwo > 0">
            <el-col :span="8" v-if="dataForm.phaseOne > 0">
              <label class='fontsize'>已支付金额：</label><span>{{ dataForm.payTotalSum === null ? 0 : dataForm.payTotalSum }} 元</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.phaseOne">
              <label class='fontsize'>一期应支付金额：</label><span>{{ dataForm.phaseOne }} 元</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.phaseTwo">
              <label class='fontsize'>二期应支付金额：</label><span>{{ dataForm.phaseTwo }} 元</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.phaseThree">
              <label class='fontsize'>三期应支付金额：</label><span>{{ dataForm.phaseThree }} 元</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.phaseFour">
              <label class='fontsize'>四期应支付金额：</label><span>{{ dataForm.phaseFour }} 元</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.payPhase > 0 ">
              <label class='fontsize'>已支付期数：</label><span>{{ dataForm.payPhase - 1 }}</span>
            </el-col>
          </template>
<!--          <el-col :span="24"  v-if="!dataForm.payComplete">-->
<!--            <label class='fontsize'>上传支付凭证：</label>-->
<!--            <el-upload-->
<!--            action="#"-->
<!--            :on-change="payHandleUpload"-->
<!--            :http-request="payHttpRequest"-->
<!--            :on-remove="payHandleRemove"-->
<!--            :before-upload="payHandleBeforeUpload"-->
<!--            :before-remove="payBeforeRemove"-->
<!--            :limit="1"-->
<!--            :on-exceed="payHandleExceed"-->
<!--            :file-list="payFileList"-->
<!--            style="display: inline-block"-->
<!--            ref="myupload">-->
<!--            <el-button size="small" type="primary">上传<span v-if="dataForm.payPhase">第{{dataForm.payPhase}}期</span>支付凭证</el-button>-->
<!--          </el-upload>-->
<!--            <div class="uf-ac ml-5 c-base" style="display: inline-block">-->
<!--              <div class="ml-1 f14 "> <i class="el-icon-warning "></i>分期付款时，每期支付凭证上传都要包括之前各期的支付凭证一起打包上传</div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          <el-col :span="24" v-else>-->
<!--            <label class='fontsize'>支付凭证：</label>-->
<!--            <a :href="$cons.SERVER_PATH + this.dataForm.payUrl"><img src="~@/assets/img/ZIP.png" style="width: 20px; height: 20px; vertical-align: middle">支付凭证查看</a>-->
<!--          </el-col>-->
      </el-row>
    </el-card>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>联系人信息</h3>
      </div>
      <el-row :gutter="20" class="row-box">
        <el-col :span="8">
          <label class='fontsize'>订单联系人信息：</label><span>{{ dataForm.contacts }}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>订单联系人电话：</label><span>{{ dataForm.mobile }}</span>
        </el-col>
        <el-col :span="8">
          <label class='fontsize'>订单联系人邮箱：</label><span>{{ dataForm.email }}</span>
        </el-col>
      </el-row>
    </el-card>
<!--    发票信息-->
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>发票信息</h3>
      </div>
      <div v-if="dataForm.invoiceApplyStatus === '1'">不开发票</div>
      <div v-else>
        <template v-if="dataForm.serviceOrderInvoiceEntity">
          <el-row :gutter="20" class="row-box" >
            <el-col :span="8">
              <label class='fontsize'>发票类型：</label><span>{{ invoiceTypeDict[dataForm.serviceOrderInvoiceEntity.invoiceType] }}</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>抬头类型：</label><span>{{ invoiceHeaderTypeDict[dataForm.serviceOrderInvoiceEntity.invoiceHeaderType] }}</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>发票抬头：</label><span>{{ dataForm.serviceOrderInvoiceEntity.headerName }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row-box" >
            <el-col :span="8" v-if="dataForm.serviceOrderInvoiceEntity.invoiceHeaderType === 'COMPANY' || dataForm.serviceOrderInvoiceEntity.invoiceType === '2'">
              <label class='fontsize'>发票税号：</label><span>{{ dataForm.serviceOrderInvoiceEntity.dutyParagraph }}</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>授权文件：</label>
<!--              <span>{{ dataForm.serviceOrderInvoiceEntity.authorizationPath }}</span>-->
              <el-link :href="$cons.SERVER_PATH + dataForm.serviceOrderInvoiceEntity.authorizationPath" target="_blank" type="primary"
                       :underline="false" v-if="dataForm.serviceOrderInvoiceEntity.authorizationPath">点击查看</el-link>
              <span v-else>无</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>发票状态：</label><span>{{ invoiceStatusDict[dataForm.serviceOrderInvoiceEntity.invoiceApplyStatus] }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row-box" v-if="dataForm.serviceOrderInvoiceEntity.invoiceApplyStatus === '4'">
            <el-col>
              <label class='fontsize'>驳回意见：</label><span style="color:#F56C6C;">{{ dataForm.serviceOrderInvoiceEntity.invoiceingRemark }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row-box" v-if="dataForm.serviceOrderInvoiceEntity.invoiceingFilePath && dataForm.serviceOrderInvoiceEntity.invoiceType === '1'">
            <el-col>
              <label class='fontsize'>电子发票：</label>
              <el-link :href="$cons.SERVER_PATH + dataForm.serviceOrderInvoiceEntity.invoiceingFilePath" target="_blank" type="primary"
                       :underline="false">点击查看</el-link>
            </el-col>
          </el-row>
        </template>
        <template v-if="dataForm.serviceOrderInvoiceEntity && dataForm.serviceOrderInvoiceEntity.invoiceType === '2'">
          <el-row :gutter="20" class="row-box" >
            <el-col :span="8">
              <label class='fontsize'>收票人：</label><span>{{ dataForm.serviceOrderInvoiceEntity.receiveName }}</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>收票电话：</label>
                            <span>{{ dataForm.serviceOrderInvoiceEntity.receiveMobile }}</span>
            </el-col>
            <el-col :span="8">
              <label class='fontsize'>收票地址：</label>
              <span>{{ dataForm.serviceOrderInvoiceEntity.receiveProvinceCode + '-' +
                dataForm.serviceOrderInvoiceEntity.receiveCityCode + '-' + dataForm.serviceOrderInvoiceEntity.receiveCountyCode}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="row-box">
            <el-col :span="8">
              <label class='fontsize'>详细地址：</label><span>{{ dataForm.serviceOrderInvoiceEntity.receiveAddress }}</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.serviceOrderInvoiceEntity.invoiceApplyStatus === '3'">
              <label class='fontsize'>邮寄快递：</label><span>{{ dataForm.serviceOrderInvoiceEntity.mailCompany }}</span>
            </el-col>
            <el-col :span="8" v-if="dataForm.serviceOrderInvoiceEntity.invoiceApplyStatus === '3'">
              <label class='fontsize'>邮寄单号：</label><span>{{ dataForm.serviceOrderInvoiceEntity.mailCode }}</span>
            </el-col>
          </el-row>
        </template>
      </div>
    </el-card>

    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>订单备注信息</h3>
      </div>
      <div class="uf uf-ac">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请填写办理事项说明（填写该订单与客户商定的全部办理事项）"
          v-model="dataForm.notes"
          :disabled="true">
        </el-input>
      </div>
    </el-card>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>合同扫描件</h3>
      </div>
      <div v-if="this.dataForm.contractPic" >
        <a :href="$cons.SERVER_PATH + this.dataForm.contractPic"><img src="~@/assets/img/ZIP.png" style="width: 20px; height: 20px; vertical-align: middle">订单合同下载</a>
      </div>
      <div v-else>
        <h3>暂未生成订单合同！</h3>
      </div>
    </el-card>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
      <div class="i-title">
        <h3><i class="el-icon-s-order mr-1"></i>打分评价</h3>
      </div>
      <div class="pb-3">
        <div class="title fwb f17 pb-4 ">用户打分</div>
        <el-row :gutter="20" class="row-box">
          <el-col :span="6">
            <label class='fontsize'>用户姓名：</label><span>{{scoreData.userName}}</span>
          </el-col>
          <el-col :span="9">
            <label class='fontsize'>产品评分：</label>
            <span style="display: inline-flex">
            <el-rate
                disabled
                v-model="scoreData.productScore"
                :colors="colors"
                show-text
                :texts="['极差', '差', '一般', '满意', '非常满意']"
            ></el-rate>
          </span>
          </el-col>
          <el-col :span="9">
            <label class='fontsize'>服务商评分：</label>
            <span style="display: inline-flex">
            <el-rate
                disabled
                v-model="scoreData.supportScore"
                :colors="colors"
                show-text
                :texts="['极差', '差', '一般', '满意', '非常满意']"
            ></el-rate>
          </span>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="row-box">
          <el-col :span="24">
            <div >
              <label class="fontsize">评价内容：</label>
              <div class="pt-3" >
                <el-input type="textarea" v-model="scoreData.comment"  readonly    :autosize="{ minRows: 4, maxRows: 4}"  ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <div class="title fwb f17 pb-4">服务商回评</div>
        <el-row :gutter="20" class="row-box">
          <el-col :span="8">
            <label class="fontsize">回评打分：</label>
            <span style="display: inline-flex">
            <el-rate
                disabled
                v-model="providerData.score"
                :colors="colors"
                show-text
                :texts="['极差', '差', '一般', '满意', '非常满意']"
            ></el-rate>
          </span>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="row-box">
          <el-col :span="24">
            <label class="fontsize ">评价内容：</label>
            <div class="pt-3" >
              <el-input type="textarea" v-model="providerData.comment"  readonly    :autosize="{ minRows: 4, maxRows: 4}"  ></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <template v-if="stepActive === 3 & dataForm.itemCode === '400101'">
      <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
        <div class="i-title">
          <h3><i class="el-icon-s-order mr-1"></i>区域品牌评估</h3>
        </div>
        <el-table
          :data="orderExtraList"
          border
          style="margin-left: 80px;width: 80%">
          <el-table-column
            prop="orderNo"
            header-align="center"
            align="center"
            width="180"
            label="订单号">
          </el-table-column>
          <el-table-column
            prop="localBrandScore"
            header-align="center"
            align="center"
            width="260"
            label="区域品牌评估分数">
          </el-table-column>
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            label="操作">
            <template>
              <el-button type="text" size="small" @click="brandDesc">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>
    <template v-else-if="stepActive === 3 & dataForm.itemCode === '400102'">
      <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px">
        <div class="i-title">
          <h3><i class="el-icon-s-order mr-1"></i>知名品牌评估</h3>
        </div>
        <el-table
          :data="orderExtraList"
          border
          style="margin-left: 80px;width: 80%">
          <el-table-column
            prop="orderNo"
            header-align="center"
            align="center"
            width="180"
            label="订单号">
          </el-table-column>
          <el-table-column
            prop="famousBrandScore"
            header-align="center"
            align="center"
            width="260"
            label="知名品牌评估分数">
          </el-table-column>
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            label="操作">
            <template>
              <el-button type="text" size="small" @click="brandDesc">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>
    <!-- <div style="text-align: center">
      <el-button v-if="dataForm.payStatus === 1" type="primary" @click="openPayment()">确认支付</el-button>
      <el-button v-if="dataForm.payStatus === 2" type="danger" @click="orderRefund()">申请退款</el-button>
    </div> -->
    <!-- 支付弹窗 -->
    <pay v-if="payVisible" ref="pay" @closeCallback="payCloseCallback"/>
    <brand-assess v-if="assessVisible" ref="brandAssessDialog" @closeCallBack="closeCallback"></brand-assess>
  </div>
</template>

<script>
  import { checkIsNull } from '../../../../utils'
  import Pay from '../../../service/pay'
  import brandAssess from './brand-assess-dialog'

  export default {
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    components: {Pay, brandAssess},
    data () {
      return {
        list: 'add',
        visible: false,
        showTable: false,
        dialogTableVisible: false,
        staffVisible: false,
        assessVisible: false,
        dataForm: {
        },
        scoreData:{},
        providerData: [],
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
        fileList: [],
        orderExtraList: [],
        payFileList: [],
        textarea: '',
        licenseUrl: '',
        selectUser: '',
        dataListLoading: false,
        payVisible: false,
        dataList: [],
        orderStatusDict: {},
        payStatusDict: {},
        deliveryStatusDict: {},
        payCompleteDict: {},
        priceTypeDict: {},
        payTypeDict: {},
        invoiceTypeDict: {},
        invoiceHeaderTypeDict: {},
        invoiceStatusDict: {},
        page: '',
        step: [{title: '订单待确认'}, {title: '订单办理中', description: '交付人员正在办理'}, {title: '订单已完成'}],
        stepActive: 0
      }
    },
    created () {
      // 获取字典值
      this.$getDictMap('ORDER_STATUS,PAY_STATUS,DELIVERY_STATUS,COMMON_0_1,PRICE_TYPE,OP_TYPE,INVOICE_TYPE,INVOICE_HEADER_TYPE,INVOICE_APPLY_STATUS', (dict) => {
        this.orderStatusDict = dict.ORDER_STATUS
        this.payStatusDict = dict.PAY_STATUS
        this.deliveryStatusDict = dict.DELIVERY_STATUS
        this.payCompleteDict = dict.COMMON_0_1
        this.priceTypeDict = dict.PRICE_TYPE
        this.payTypeDict = dict.OP_TYPE
        this.invoiceTypeDict = dict.INVOICE_TYPE
        this.invoiceHeaderTypeDict = dict.INVOICE_HEADER_TYPE
        this.invoiceStatusDict = dict.INVOICE_APPLY_STATUS
      })
      this.getDataList()
      this.getScore()
    },
    methods: {
      closeCallback (refresh) {
        this.$emit('closeCallback', refresh)
      },
      payCloseCallback (refresh) {
        if (refresh) {
          this.checked = true
        }
      },

      isNull (a) {
        if (checkIsNull(a)) {
          return true
        } else {
          return false
        }
      },
      // 页面回调
      goBack (refresh) {
        this.page = 'list'
        if (refresh) {
          this.getDataList(true)
        }
      },
      // 查询获取订单数据
      getDataList () {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl(`${this.$api.ORDER.INFO}/${this.id}`),
          method: 'get',
          params: this.$http.adornParams({
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.info
            this.stepActive = this.dataForm.orderStatus
            let orderExtra = data.info.orderExtra
            this.orderExtraList = []
            this.orderExtraList.push(orderExtra)
            this.getServiceInfo(this.dataForm.serviceId)
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      // 用户评论信息
      getScore () {
        this.$http({
          url: this.$http.adornUrl(this.$api.ORDER_COMMENT.ID_INFO),
          methods: 'get',
          params: this.$http.adornParams({
            orderId: this.id
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.scoreData = data.serviceUserComment || []
            if (this.scoreData.serviceSupportCommentEntity === null) {
              this.scoreData.serviceSupportCommentEntity = []
              this.providerData = this.scoreData.serviceSupportCommentEntity
            } else {
              this.providerData = this.scoreData.serviceSupportCommentEntity || []
            }
          }
        })
      },
      //获取产品信息
      getServiceInfo (id) {
        this.$http({
          url: this.$http.adornUrl(this.$api.PUB.SERVICE_INFO + '/' + id),
          method: 'POST'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm.templateFilePath = data.serviceInfo.templateFilePath
            this.$forceUpdate()
          }
        })
      },
      openPayment () {
        // TODO 打开支付弹窗
        this.payVisible = true
        this.$nextTick(() => {
          this.$refs['pay'].init(this.dataForm)
        })
      },
      brandDesc () {
        this.assessVisible = true
        this.$nextTick(() => {
          this.$refs.brandAssessDialog.init(this.dataForm.orderNo, this.dataForm.itemCode)
        })
      },
      // 退款
      orderRefund (rowData) {
        // TODO 退款接口
      },
      /**
       * 上传license成功回调
       */
      handleUpload (response = {}, file) {
        this.fileList = file
      },
      httpRequest (data) {
        this.dataForm.file = data.file
        let formData = new FormData()
        formData.append('file', this.dataForm.file)
        formData.append('orderId', this.dataForm.id)
        formData.append('userId', this.userId)
        this.$http({
          url: this.$http.adornUrl(this.$api.UPLOAD.ORDER_FILE),
          method: 'post',
          data: formData
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$http({
              url: this.$http.adornUrl(this.$api.ORDER.FILE),
              method: 'post',
              params: this.$http.adornParams({
                orderId: this.dataForm.id,
                orderFile: this.$deepClone(data.path)
              })
            }).then(({data}) => {
              if (data.code === 0) {
               this.$message.success('附件上传成功')
                this.getDataList()
              } else {
                this.$message.error(data.msg)
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      handleRemove (file, fileList) {
        this.dataForm.file = null
      },
      handleExceed (files, fileList) {
        this.$message.warning('当前限制选择 1 个文件')
      },
      beforeRemove (file) {
        return this.$confirm(`确定要移除${file.name}?`)
      },
      handleBeforeUpload (file) {
        const typeList = ['zip', 'rar']
        const fileName = file.name
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1)
        const isRT = typeList.includes(extension)
        if (!isRT) {
          this.$message.error('请上传压缩文件, 格式为zip或rar，模板格式查看【模板文件】')
          return false
        }
      },
      /**
       * 上传支付凭证成功回调
       */
      payHandleUpload (response = {}, file) {
        this.payFileList = file
      },
      payHttpRequest (data) {
        this.dataForm.file = data.file
        let formData = new FormData()
        formData.append('file', this.dataForm.file)
        formData.append('orderId', this.dataForm.id)
        this.$http({
          url: this.$http.adornUrl(this.$api.UPLOAD.PAYMENT),
          method: 'post',
          data: formData
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$http({
              url: this.$http.adornUrl(this.$api.ORDER.PAY),
              method: 'post',
              params: this.$http.adornParams({
                orderId: this.dataForm.id,
                payUrl: this.$deepClone(data.path)
              })
            }).then(({data}) => {
              if (data.code === 0) {
                this.$message.success('支付凭证上传成功')
                this.getDataList()
                this.payFileList = []
              } else {
                this.$message.error(data.msg)
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      },
      payHandleRemove (file, fileList) {
        this.dataForm.file = null
      },
      payHandleExceed (files, fileList) {
        this.$message.warning('当前限制选择 1 个文件')
      },
      payBeforeRemove (file, fileList) {
        return this.$confirm(`确定移除${file.name}？`)
      },
      payHandleBeforeUpload (file) {
        const typeList = ['zip', 'rar']
        const fileName = file.name
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1)
        const isRT = typeList.includes(extension)
        if (!isRT) {
          this.$message.error('请上传压缩文件, 格式为zip或rar，模板格式查看【模板文件】')
          return false
        }
      }
    }
  }
</script>

<style scoped>
  .i-row {
    display: flex;
    /*height: 36px;*/
    line-height: 36px;
    color: #303133;
  }
  .i-row div+div:not(:first-child) {
    margin-left: 12rem;
  }
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .i-row label {
    font-weight: bold;
    color: #1a2633;
  }
  .el-uploader {
    width: 180px;
    height: 160px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-left: 1.5rem;
  }

  .avatar-uploader .el-uploader:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    width: 140px;
    height: 110px;
    line-height: 150px;
    text-align: center;
  }

  .avatar {
    width: 180px;
    height: 160px;
    display: block;
  }

  .fontsize {
    font-weight: 600;
  }
  .row-box [class*=" el-col-"]{
    font-size: 14px;
    padding:10px 10px;
    /* margin-left: 20px; */
  }
  .close {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}
</style>
