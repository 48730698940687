<template>
    <div class="invoice-query">
        <div v-if="!orderDetailVisible" class="uf uf-column hi100">
            <div>
                <el-form :inline="true" :model="dataForm" @keyup.enter.native="getInvoiceList(true)">
                    <el-form-item>
                        <el-select v-model="dataForm.invoiceApplyStatus" placeholder="发票状态" clearable>
                            <el-option
                                    v-for="item in invoiceStatusList"
                                    :key="item.dictId"
                                    :label="item.dictName"
                                    :value="item.dictId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="dataForm.orderNo" placeholder="订单号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="申请日期">
                        <el-date-picker
                                v-model="dataForm.time"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="getInvoiceList()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="uf-f1" style="overflow:auto;">
                <el-table
                        :data="dataList"
                        border
                        height="100%"
                        v-loading="dataListLoading"
                        style="width: 100%;">
                    <el-table-column type="index" label="序号" header-align="center" align="center" width="80" :index="indexMethod"></el-table-column>
                    <el-table-column label="订单号" prop="orderId" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.serviceOrderEntity">{{scope.row.serviceOrderEntity.orderNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="需求产品" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.serviceOrderEntity">{{scope.row.serviceOrderEntity.serviceName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="下单时间" prop="phone" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.serviceOrderEntity">{{scope.row.serviceOrderEntity.createTime}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="金额(元)" prop="address" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.serviceOrderEntity">{{scope.row.serviceOrderEntity.totalSum}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请开票时间" prop="submitTime" header-align="center" align="center"></el-table-column>
                    <el-table-column label="发票类型" prop="invoiceType" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{invoiceTypeDict[scope.row.invoiceType]}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="发票状态" prop="invoiceApplyStatus" header-align="center" align="center">
                        <template slot-scope="scope">
<!--                            <span>{{invoiceStatusDict[scope.row.invoiceApplyStatus]}}</span>-->
                            <el-tag :type="invoiceApplyStatusType[scope.row.invoiceApplyStatus]">{{ invoiceStatusDict[scope.row.invoiceApplyStatus] }}</el-tag>
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="邮寄状态" prop="address" header-align="center" align="center"></el-table-column>-->
                    <el-table-column label="操作" prop="address" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="orderInfo(scope.row)">详情</el-button>
                            <el-button type="text" size="small" v-if="scope.row.invoiceApplyStatus === '3' && scope.row.invoiceType === '1'" @click="invoiceCheck(scope.row)">查看发票</el-button>
                            <el-button type="text" size="small" v-if="scope.row.invoiceApplyStatus === '4'" @click="reapply(scope.row)">重开</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="uf uf-ac uf-pe mt-2">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageIndex"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="totalCount"
                        layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
        <!--        订单详情-->
        <OrderDetail v-if="orderDetailVisible" ref="orderDetailRef" :id="orderId" @goBack="goBack"></OrderDetail>
<!--        发票重申-->
        <invoiceEditing v-if="invoiceEditingVisible" ref="invoiceEditingRef" @invoiceCallBack="invoiceCallBack"></invoiceEditing>
    </div>
</template>

<script>
    import moment from 'moment'
    import OrderDetail from '../order/order-detail'
    import invoiceEditing from '../order/invoice-apply'
    export default {
        name: 'invoice-address',
        components: {
            OrderDetail,
            invoiceEditing
        },
        data () {
            return {
                dataForm: {
                    invoiceApplyStatus: '',
                    orderId: '',
                    time: []
                },
                dataList: [],
                dataListLoading: false,
                //    分页参数
                pageIndex: 1,
                pageSize: 10,
                totalCount: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近一个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近三个月',
                        onClick (picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                            picker.$emit('pick', [start, end])
                        }
                    }]
                },
                dict: {},
                invoiceStatusList: [],
                invoiceTypeDict: {},
                invoiceStatusDict: {},
            //    订单详情
                orderDetailVisible: false,
                orderId: '',
                // 发票重申弹框显隐
                invoiceEditingVisible: false,
                invoiceApplyStatusType: {'1': 'info', '2': 'warning', '3': 'success', '4': 'danger'}
            }
        },
        created () {
            this.$getDictList('INVOICE_APPLY_STATUS', dict => {
                let invoiceStatusList = dict['INVOICE_APPLY_STATUS']
                this.invoiceStatusList = invoiceStatusList.filter(item => {
                    return item.dictId !== '1'
                })

            })
            this.$getDictMap('INVOICE_TYPE,INVOICE_APPLY_STATUS', dict => {
                this.invoiceTypeDict = dict['INVOICE_TYPE']
                this.invoiceStatusDict = dict['INVOICE_APPLY_STATUS']
            })
            this.$nextTick(() => {
                this.getInvoiceList()
            })
        },
        methods: {
            //   获取发票列表
            getInvoiceList (isPage = false) {
                if (isPage) {
                    this.pageIndex = 1
                }
                this.dataListLoading = true
                let timeList = this.$deepClone(this.dataForm.time) || []
                this.$http({
                    url: this.$http.adornUrl(this.$api.INVOICE.INVOICE_LIST),
                    method: 'GET',
                    params: this.$http.adornParams({
                        page: this.pageIndex,
                        limit: this.pageSize,
                        orderNo: this.dataForm.orderNo,
                        invoiceApplyStatus: this.dataForm.invoiceApplyStatus,
                        startSubmitTime: timeList && timeList.length > 0 ? moment(timeList[0]).format('YYYY-MM-DD 00:00:00') : '',
                        endSubmitTime: timeList && timeList.length > 0 ? moment(timeList[1]).format('YYYY-MM-DD 23:29:59') : ''
                    })
                }).then(({data}) => {
                    this.dataListLoading = false
                    if (data && data.code === 0) {
                        let page = data.page || {}
                        this.dataList = page.list || []
                        this.totalCount = page.totalCount || 0
                    }
                })
            },
            // 订单详情
            orderInfo (item) {
                this.orderDetailVisible = true
                this.orderId = item.orderId || ''
            },
            // 订单详情回调
            goBack () {
                this.orderDetailVisible = false
            },
            // 发票重申
            reapply (item) {
                this.invoiceEditingVisible = true
                this.$nextTick(() => {
                    this.$refs.invoiceEditingRef.init(item, true)
                })
            },
            // 发票重申回调
            invoiceCallBack (isRefresh = false) {
                this.invoiceEditingVisible = false
                if (isRefresh) {
                    this.getInvoiceList(true)
                }
            },
            // 查看发票
            invoiceCheck (item) {
                window.open(this.$cons.SERVER_PATH + item.invoiceingFilePath)
            },
            // 每页数
            sizeChangeHandle (val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getInvoiceList()
            },
            // 当前页
            currentChangeHandle (val) {
                this.pageIndex = val
                this.getInvoiceList()
            },
            indexMethod (index) {
                return (this.pageIndex - 1) * this.pageSize + index + 1
            }
        }
    }
</script>

<style scoped>
    .invoice-query {
        height: calc(100vh - 220px);
    }

</style>