<template>
  <el-dialog
    width="70%"
    :visible.sync="visible"
    :append-to-body="true">
    <div slot="title">
      <div style="float: left" v-if="serviceType === '4001'">
        <span class="f20">区域品牌评估</span>
      </div>
      <div style="float: left" v-if="serviceType === '4002'">
        <span class="f20">知名品牌评估</span>
      </div>
    </div>
    <template v-for="(firstIndex, first) in indexList">
      <div class="i-title" v-bind:key="first.id">
        <h1><i class="el-icon-s-order mr-1"></i>{{ firstIndex.indexName }}</h1>
      </div>
        <template v-for="(secondIndex, second) in firstIndex.childrenList">
          <div class="i-title" v-bind:key="second.id">
            <h2><i class="el-icon-s-order mr-1"></i>{{ secondIndex.indexName }}</h2>
          </div>
            <template v-for="(nodeIndex, node) in secondIndex.childrenList">
              <div class="i-title" v-bind:key="node.id">
                <h3><i class="el-icon-s-order mr-1"></i>{{ nodeIndex.indexName }}</h3>
              </div>
              <div class="pl-4" v-bind:key="node.id">
                <el-row justify="space-around" class="pt-3">
                  <el-col :span="4" class="tar pt-3"><span>指标描述</span></el-col>
                  <el-col :span="12" class="ml-2"><el-input placeholder="请输入指标描述" v-model="nodeIndex.indexResult" type="textarea" readonly></el-input></el-col>
                </el-row>
                <el-row justify="space-around" class="pt-3">
                  <el-col :span="20" :offset="4">注: {{ nodeIndex.indexDesc }}</el-col>
                </el-row>
                <el-row justify="space-around" class="pt-3">
                  <el-col :span="4" class="tar pt-2" >数据来源</el-col>
                  <el-col :span="6" class="ml-2"><el-input placeholder="请输入数据来源" v-model="nodeIndex.dataSources" readonly></el-input></el-col>
                </el-row>
                <el-row justify="space-around" class="pt-3">
                  <el-col :span="4" class="tar pt-2 " >指标值</el-col>
                  <el-col :span="6" class="ml-2"><el-input placeholder="请输入指标值" type="number" :max="nodeIndex.indexStandard" v-model="nodeIndex.indexScore" readonly></el-input></el-col>
                </el-row>
              </div>
            </template>
        </template>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'brand-assess-dialog',
    data () {
      return {
        visible: false,
        saveLoding: false,
        orderNo: '',
        serviceType: '',
        brandAssessInfo: {},
        indexList: []
      }
    },
    methods: {
      init (sourceOrderNo, serviceType) {
        this.orderNo = sourceOrderNo
        this.serviceType = serviceType
        this.visible = true
        this.$http({
          url: this.$http.adornUrl(this.$api.BRAND.INDEX_LIST),
          method: 'get',
          params: this.$http.adornParams({
            'orderNo': sourceOrderNo,
            'serviceType': serviceType
          })
        }).then(({data}) => {
          this.indexList = data.dataList
        })
      },
    }
  }
</script>

<style scoped>
  .i-row {
    display: flex;
    /*height: 36px;*/
    line-height: 36px;
    color: #303133;
  }
  .i-row div+div:not(:first-child) {
    margin-left: 4rem;
  }
  .i-title h1 {
    margin: 22px 0 6px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 7px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .i-title h2 {
    padding-left: 22px;
    margin: 22px 0 6px;
    font-weight: bold;
    font-size: 17px;
    color: #000000;
    padding-bottom: 7px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .i-title h3 {
    padding-left: 44px;
    margin: 22px 0 6px;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 7px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .i-row label {
    font-weight: bold;
    color: #1a2633;
  }
</style>
